import { ListOfCategoriesImages } from '@/organisms';

import { listOfCategoriesTransformers } from '@/lib/graphql/transformers/flexible';

/**
 * ListOfCategoriesImagesTemplate
 */
const ListOfCategoriesImagesTemplate = ({ bloque, id, className }: any) => (
  <ListOfCategoriesImages
    {...listOfCategoriesTransformers(bloque, id)}
    {...(className && { className })}
  />
);

ListOfCategoriesImagesTemplate.displayName = 'ListOfCategoriesImagesTemplate';

export default ListOfCategoriesImagesTemplate;
