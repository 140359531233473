import type { TypeListOfCategoriesImagesProps } from '@/organisms/list-of-categories-images';
import type { ListOfCategoriesGraphQL } from '../../types/flexible';

export const listOfCategoriesTransformers = (
  bloque: ListOfCategoriesGraphQL,
  id: string,
): TypeListOfCategoriesImagesProps => ({
  ...(bloque.title && {
    title: bloque.title,
  }),
  theme: bloque.theme,
  ...(bloque.showArrows && {
    showArrow: bloque.showArrows,
  }),
  ...(bloque.papelRasgado && {
    papelRasgado: bloque.papelRasgado,
  }),
  ...(bloque.desktopCategories && {
    desktopCategories: bloque.desktopCategories.map((category) => ({
      image: {
        altText: category.image.altText,
        sourceUrl: category.image.sourceUrl,
      },
      link: category.link.url,
      title: category.title,
    })),
  }),
  ...(bloque.mobileCategories && {
    mobileCategories: bloque.mobileCategories.map((category) => ({
      image: {
        altText: category.image.altText,
        sourceUrl: category.image.sourceUrl,
      },
      link: category.link.url,
      title: category.title,
    })),
  }),
  id,
});
