import Image from 'next/image';

import { ConditionalWrapper } from '@/hoc';
import { NextLink } from '@/atoms';

import { removeBaseURL } from '@/lib/utils';
import FallBackIMG from '@/assets/images/fallback-product-card.jpg';

import type { FunctionComponent } from 'react';
import type { TypeCategoryProps } from './types';

/**
 * Category
 */
export const Category: FunctionComponent<TypeCategoryProps> = ({
  title,
  image,
  link,
}: TypeCategoryProps) => (
  <ConditionalWrapper
    condition={link !== ''}
    wrapper={(c) => (
      <NextLink href={removeBaseURL(link)}>
        <a>{c}</a>
      </NextLink>
    )}
  >
    <div className="grid place-items-center">
      <div className="u-aspect-square relative w-[70px] md:w-[85px] [&>span]:!overflow-visible">
        <Image
          alt={image.altText ?? 'Category slider image'}
          src={
            image.sourceUrl && image.sourceUrl !== ''
              ? image.sourceUrl
              : FallBackIMG.src
          }
          layout="fill"
          objectFit="contain"
          draggable="false"
          objectPosition="center"
          className="transition-transform hover:scale-105"
        />
      </div>

      <p className="u-body u-body--s hyphens-auto text-center normal-case md:u-body--m">
        {title}
      </p>
    </div>
  </ConditionalWrapper>
);
Category.displayName = 'Category';
